<template>
  <div>
    <el-form label-width="50px">
      <el-row :gutter="10"
              style="padding-bottom:10px">
        <el-col :lg="6"
                :md="12"
                v-if="home_id == 1 "
                :sm="12"
                :xl="6"
                :xs="24">
          <el-form-item label="学校:">
            <el-select v-model="school_id"
                       filterable
                       clearable
                       style="width:100%"
                       @change="schoolChange"
                       placeholder="选择学校">
              <el-option v-for="item in schoolList"
                         :key="item.school_id"
                         :label="item.school_name"
                         :value="item.school_id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :lg="6"
                :md="12"
                :sm="12"
                :xl="6"
                :xs="24">
          <el-form-item label="届:">
            <el-select v-model="form.graduated_id"
                       style="width:100%"
                       filterable
                       @change="jieChange"
                       placeholder="选择届">
              <el-option v-for="item in jieList"
                         :key="item.graduated_id"
                         :label="item.year_desc"
                         :value="item.graduated_id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :lg="6"
                :md="12"
                :sm="12"
                v-if="school_id != 1"
                :xl="6"
                :xs="24">
          <el-form-item label="班级:">
            <el-select v-model="form.class_id"
                       style="width:100%"
                       filterable
                       @change="classChange"
                       placeholder="选择班级">
              <el-option v-for="item in classList"
                         :key="item.id"
                         :label="item.class_name"
                         :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :lg="6"
                :md="12"
                :sm="12"
                :xl="6"
                :xs="24">
          <el-form-item label="科目:">
            <el-select style="width:100%"
                       :clearable="school_id  == 1"
                       v-model="form.subject_id"
                       placeholder="选择科目">
              <el-option v-for="(item,index) in subjectList"
                         :key="index"
                         :label="item.subject_name"
                         :value="item.subject_id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <!-- <el-col :lg="6"
                :md="12"
                :sm="12"
                :xl="6"
                :xs="24">
          <el-form-item label="年级:">
            <el-select style="width:100%"
                       :disabled="school_id != 1"
                       v-model="form.grade_id"
                       :clearable="school_id  == 1"
                       @change="gradeChange"
                       placeholder="选择年级">
              <el-option v-for="item in gradeList"
                         :key="item.grade_id"
                         :label="item.grade_name"
                         :value="item.grade_id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col> -->
        <el-col :span="15">
          <el-button type="primary"
                     @click="initData">查询</el-button>
        </el-col>
      </el-row>
    </el-form>
    <Table ref="TablesRef"
           :tableData="tableData" />
  </div>
</template>

<script>
import testVue from '../../test.vue'
export default {
  name: 'weaknessStatic',
  data () {
    return {
      page: 1,
      size: 10,
      totalElements: 0,
      subjectList: [],
      tableData: [],
      classList: [],
      jieList: [],
      gradeList: [],
      schoolList: [],
      school_id: '',
      form: {
        graduated_id: '',
        class_id: '',
        subject_id: '',
        grade_id: ''
      },
      home_id: 0,
      tableData: [],
      pageName: 'cz_student_weakness_admin',
      tableColumns: [
        // { prop: 'knowledge_id', align: 'center', label: '知识点ID', width: '', showToopic: false },
        { prop: 'knowledge_desc', align: 'center', label: '知识点名称', width: '', showToopic: true },
        { prop: 'count', align: 'center', label: '所有学生做题次数', width: '', showToopic: false },
        { prop: 'score', align: 'center', label: '所有学生总得分率', width: '', showToopic: false },
        {
          prop: 'd', align: 'center', label: '操作', width: '', type: 'img', btns: [
            { content: '知识点详细数据', type: 'look', event: 'toDetail' },
          ]
        },
      ]
    }
  },
  mounted () {
    this.setTablesColums()
    this.getJie()
    this.getRole()
  },
  methods: {
    getRole () {
      this.$http({
        url: '/api/v1/public/homepage_role',
        method: 'get',
      }).then(res => {
        this.home_id = res.data.home_id
      })
    },
    schoolChange () {
      this.getClassList()
    },
    getSchoolList () {
      this.$http({
        url: '/api/v1/main/get_user_school',
        method: 'get'
      }).then(res => {
        this.schoolList = res.data.list

        if (this.home_id == 1) {
          this.school_id = this.schoolList[0].school_id
        } else {
          this.school_id = JSON.parse(window.localStorage.getItem('userInfo')).edu_school_id
        }
        if (this.school_id != 1) {
          this.getClassList()
        } else {
          this.getSLsubjectList()
        }
      })
    },
    clearClass () {
      this.tableData = []
    },
    getJie () {
      this.$http({
        url: '/api/v1/public/graduated_lst',
        method: 'get'
      }).then(res => {
        this.jieList = Object.values(res.data)
        this.form.graduated_id = this.jieList[0].graduated_id
        this.getSchoolList()
      })
    },
    getClassList () {
      this.$http({
        url: '/api/v1/special/get_class',
        method: 'get',
        params: {
          graduated_id: this.form.graduated_id,
          school_id: this.school_id
        }
      }).then(res => {
        this.classList = res.data.list
        this.form.class_id = res.data.list[0].id || ''
        this.classChange()
      })
    },
    setTablesColums () {
      this.$refs.TablesRef.pageName = this.pageName
      this.$refs.TablesRef.setColumns(this.tableColumns)
    },
    initData () {
      if (this.school_id != 1 && (!this.form.graduated_id || !this.form.class_id || !this.form.grade_id || !this.form.subject_id)) {
        return this.$message.warning('请选择届，班级，科目，年级')
      }
      this.$http({
        url: '/api/v1/combination/weak_list',
        method: 'get',
        params: this.form
      }).then(res => {
        this.tableData = res.data.list

        console.log(res.data.list);
        this.totalElements = res.data.list
      })
    },
    getSLsubjectList () {
      this.$http({
        url: '/api/v1/school/get_subject',
        method: 'get',
      }).then(res => {
        this.subjectList = res.data.list
        this.form.subject_id = this.subjectList[0].subject_id
        this.getGradeList()
      })
    },
    getSubjectList () {
      this.$http({
        url: '/api/v1/main/home_subject',
        method: 'get',
        params: {
          class_id: this.form.class_id
        }
      }).then(res => {
        this.subjectList = res.data.list
        if (this.subjectList.length) {
          this.form.subject_id = this.subjectList[0].subject_id
        }
        if (this.school_id != 1) {
          this.form.grade_id = this.subjectList[0].grade_id
        }
        // this.subjectChange()
      })
    },
    toDetail (row) {
      let form = {
        knowledge_id: row.knowledge_id,
        // class_id: this.form.class_id
        ...this.form
      }
      this.$router.push('/studentCondition/weakness/detail?form=' + JSON.stringify(form))
    },
    getGradeList () {
      this.$http({
        url: '/api/v1/combination/grade_list',
        method: 'get',
        params: {
          graduated_id: this.form.graduated_id,
          class_id: this.form.class_id,
          subject_id: this.form.subject_id
        }
      }).then(res => {
        this.gradeList = res.data
        if (!this.form.grade_id && this.form.subject_id) {
          this.form.grade_id = this.gradeList[0].grade_id
          this.initData()
        }
        else if (this.form.grade_id && this.form.subject_id) {
          this.initData()
        }
      })
    },
    subjectChange () {
      this.form.grade_id = ''
      if (this.school_id != 1 && this.form.subject_id) {
        let row = this.subjectList.find(item => item.subject_id == this.form.subject_id)
        if (row) {
          this.form.grade_id = row.grade_id
        }
      }
      this.getGradeList()
    },
    gradeChange () {
      this.initData()
    },
    classChange () {
      this.getSubjectList()
    },
    jieChange () {
      this.$set(this.form, 'subject_id', '')
      this.$set(this.form, 'grade_id', '')
      this.$set(this.form, 'class_id', '')
      this.subjectList = []
      if (this.school_id == 1) {
        this.getSLsubjectList()
      } else {
        this.getClassList()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
